<script setup lang="ts">
import {ArrowUpIcon} from "@heroicons/vue/24/outline";
import {usePage} from "@inertiajs/inertia-vue3";
import {computed, ref} from "vue";
import {translate} from "../../ts/helpers/translationHelper";
import {Company} from "../../ts/models/Company";

const page = usePage();
const company = ref<Company>(<Company>page.props.value.company);

const companyName = computed(() => company.value?.name);
const companyDetails = computed(() => {

	let value = "";
	if (company.value?.address?.trim()?.length > 0)
		value += company.value.address + " | ";
	if (company.value?.zipcode?.trim()?.length > 0)
		value += company.value.zipcode + " ";
	if (company.value?.city?.trim()?.length > 0)
		value += company.value.city + " ";
	if (company.value?.tel?.trim()?.length > 0)
		value += company.value.tel + " ";

	return value;
});

const bgColor = ref(page.props.value.secondaryColor);

const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

</script>

<template>
	<footer :style="'background-color:'+bgColor " class="w-full p-6">
		<div class="grid grid-cols-3">
			<div class="flex items-center">
				<h2 class="text-white font-medium text-lg">{{ companyName }}</h2>
				<span class="text-white font-medium text-sm ml-4">{{ companyDetails }}</span>
			</div>
			<a target="_blank"
			   :href="company?.terms?.original_url ?? '#'"
			   class="text-white font-semibold text-sm underline text-center">
				{{ translate('dashboard.termsButton') }}
			</a>
			<button @click="scrollToTop" class="flex justify-end">
				<ArrowUpIcon class="p-1 h-8 w-8 text-white"></ArrowUpIcon>
			</button>
		</div>
	</footer>
</template>


