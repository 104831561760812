<script setup lang="ts">
import Dropdown from "@/Components/Dropdown.vue";
import {ref} from "vue";
import {translate} from "../../ts/helpers/translationHelper";
import {usePage} from "@inertiajs/inertia-vue3";
import {PermissionEnum} from "../../ts/Enums";

const page = usePage();
const navItems = ref([
	{
		icon: '<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />',
		items: [
			{
				title: 'nav.profile',
				route: 'user.profile'
			},
			{
				title: 'nav.favorites',
				route: 'favourites.index'
			},
			{
				title: 'nav.fileshares',
				route: 'fileshare.index'
			},
			{
				title: "nav.logout",
				route: 'logout'
			}
		]
	},

]);

const checkSettingsAccess = () => {
	let topRightItem = {
		icon: '<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />',
		items: [
			{
				title: "nav.trash",
				route: "trash"
			},
			{
				title: "nav.help",
				route: "help",
				target: "_blank"
			}
		]
	};

	let menuItem = page.props.value.menu?.length > 0 ? page.props.value.menu[0] : null;
	if (menuItem != null) {
		//user has access to settings
		topRightItem.items.unshift({
			title: "nav.settings",
			route: menuItem.route
		})
	}

	navItems.value.push(topRightItem);
}

const checkTempUploads = () => {
	let topRightItem = {
		icon: '<path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />',
		items: [],
		badge: (usePage().props.value?.utility_nav?.images ?? 0) + (usePage().props.value?.utility_nav?.faces ?? 0)
	};

	let permissions = page.props.value?.auth?.permissions ?? [];
	if (permissions.findIndex(p => p === PermissionEnum.PublishFileFromTempFolder) > -1) {
		topRightItem.items.push({
			title: 'nav.image',
			route: "tempuploads.index",
			badge: (usePage().props.value?.utility_nav?.images ?? 0)
		});
	}
	if (permissions.findIndex(p => p === PermissionEnum.MutatePersonInfo) > -1) {
		topRightItem.items.push({
			title: 'nav.models',
			route: "people.index",
			badge: (usePage().props.value?.utility_nav?.faces ?? 0)
		});
	}
	if (topRightItem.items.length > 0)
		navItems.value.unshift(topRightItem);
}

checkSettingsAccess();
checkTempUploads();

</script>

<template>
	<div v-for="nav in navItems" class="ml-3 relative">
		<Dropdown align="right" width="48">
			<template #trigger>
				<span class="inline-flex rounded-md">
						<button type="button"
						        class="relative inline-flex items-center px-1 py-1 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150">
							<svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
							     fill="none" stroke="currentColor" v-html="nav.icon">
              </svg>
							<div v-if="nav.badge"
							     class="top-0 -right-0 absolute h-3 w-3 bg-red-500 rounded-full text-[10px] text-white flex flex-col justify-center text-center">
<!--								{{ nav.badge }}-->
							</div>
						</button>
				</span>
			</template>

			<template #content>
				<div class="flex flex-col px-4 py-2">
					<a class="flex gap-2 block w-full px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:underline underline-offset-4 decoration-2 decoration-gray-300 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
					   v-for="subNav in nav.items"
					   :href="route(subNav.route)"
					   :target="subNav?.target ??'_self'">
						{{ translate(subNav.title) }}
						<div class="flex flex-col justify-center">
							<div v-if="subNav.badge"
							     class="h-6 w-6 bg-red-500 rounded-full text-xs text-white flex flex-col justify-center text-center">
								{{ subNav.badge }}
							</div>
						</div>
					</a>
				</div>
			</template>
		</Dropdown>
	</div>
</template>